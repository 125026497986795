//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FacturaServices from "../../../Services/FacturaServices"

const facturaServices = new FacturaServices();
export default {
  name: "nuevoDatoFiscalModal",
  props: ["model", "datoFiscalObj", "cliente"],
  data() {
    return {
      tiposPersona: [
        {label: "Fisica", value: 0},
        {label: "Moral", value: 1},
      ],
      datoFiscalAgregar: {
        tipoPersona: "",
        rfc: "",
        razonSocial: "",
        usoCfdi: "",
        regimenFiscal: "",
        pais: "México",
        email: "",
        direccion: "",
        direccionFiscal: "",
      },
      usosCFDIlst: JSON.parse(localStorage.getItem("usosCfdi")),
      regimenFiscaleslst: JSON.parse(localStorage.getItem("regimenFiscales")),
    }
  },
  computed: {
    regimenFiscales() {
      let lst = [];
      if (this.datoFiscalAgregar.tipoPersona === 0)
        return _.filter(this.regimenFiscaleslst, {'fisica': "Sí"});
      else if (this.datoFiscalAgregar.tipoPersona === 1)
        return _.filter(this.regimenFiscaleslst, {'moral': "Sí"});
      else
        return lst;
    },
    usosCFDI() {
      let lst = [];
      if (this.datoFiscalAgregar.tipoPersona === 0)
        return _.filter(this.usosCFDIlst, {'fisica': "Sí"});
      else if (this.datoFiscalAgregar.tipoPersona === 1)
        return _.filter(this.usosCFDIlst, {'moral': "Sí"});
      else
        return lst;
    }
  },
  methods: {
    _isEmpty:_.isEmpty,
    onClose() {this.$emit('on-close');},
    onShow() {
      this.loader(true);
      this.limpiarAlta();
      this.loader(false);
      this.$nextTick(() => this.$refs.first.focus());
    },
    limpiarAlta() {
      let dfObj = this.datoFiscalObj,
          isEdit = !_.isEmpty(dfObj);

      this.datoFiscalAgregar = {
        tipoPersona: isEdit ? dfObj.tipoPersona : "",
        rfc: isEdit ? dfObj.rfc : "",
        razonSocial: isEdit ? dfObj.razonSocial : "",
        usoCfdi: isEdit ? _.filter(this.usosCFDIlst, u => u.c_uso_cfdi === dfObj.usoCfdiClave)[0] : "",
        regimenFiscal: isEdit ? _.filter(this.regimenFiscaleslst, u => u.c_regimen_fiscal === dfObj.regimenFiscalClave)[0] : "",
        pais: "México",
        email: isEdit ? dfObj.email : "",
        direccion: isEdit ? dfObj.domicilio : "",
        direccionFiscal: isEdit ? dfObj.direccionFiscal : ""
      };
    },
    validarDatoFiscalAltaEdicion() {
      if (_.isEmpty(this.datoFiscalObj))
        return this.agregarDatoFiscal();
      return this.editarDatoFiscal();
    },
    agregarDatoFiscal() {
      this.loader(true);
      let params = {
        tipoPersona: this.datoFiscalAgregar.tipoPersona,
        rfc: this.datoFiscalAgregar.rfc.toUpperCase(),
        razonSocial: this.datoFiscalAgregar.razonSocial,
        pais: this.datoFiscalAgregar.pais,
        codigoPais: "MEX",
        usoCfdi: !_.isEmpty(this.datoFiscalAgregar.usoCfdi) ? this.datoFiscalAgregar.usoCfdi.c_uso_cfdi : '',
        descripcionCfdi: !_.isEmpty(this.datoFiscalAgregar.usoCfdi) ? this.datoFiscalAgregar.usoCfdi.descripcion : '',
        direccion: this.datoFiscalAgregar.direccion,
        direccionFiscal: this.datoFiscalAgregar.direccionFiscal,
        email: this.datoFiscalAgregar.email,
        clienteId: !_.isEmpty(this.cliente) ?  this.cliente : "",
        regimenFiscal: this.datoFiscalAgregar.regimenFiscal.c_regimen_fiscal,
        regimenFiscalDescripcion: this.datoFiscalAgregar.regimenFiscal.descripcion,
      };

      return facturaServices.agregarDatoFiscal(params)
        .then(data => {
          this.alertShow("Se agregó el dato fiscal correctamente.", "exito");
          data.data.clienteFiscalId = data.data.clienteFiscalesId;
          this.$emit("get-dato-fiscal", data.data);
          this.onClose();
        }).catch(error => this.alertShow(error, "error"))
        .then(() => this.loader(false));
    },
    editarDatoFiscal() {
      this.loader(true);
      let params = {
        tipoPersona: this.datoFiscalAgregar.tipoPersona,
        rfc: this.datoFiscalAgregar.rfc.toUpperCase(),
        razonSocial: this.datoFiscalAgregar.razonSocial,
        pais: this.datoFiscalAgregar.pais,
        codigoSatPais: "MEX",
        usoCfdiClave: !_.isEmpty(this.datoFiscalAgregar.usoCfdi) ? this.datoFiscalAgregar.usoCfdi.c_uso_cfdi : '',
        usoCfdiDescripcion: !_.isEmpty(this.datoFiscalAgregar.usoCfdi) ? this.datoFiscalAgregar.usoCfdi.descripcion : '',
        datoFiscalId: this.datoFiscalObj.clienteFiscalId,
        domicilio: this.datoFiscalAgregar.direccion,
        email: this.datoFiscalAgregar.email,
        direccionFiscal: this.datoFiscalAgregar.direccionFiscal,
        regimenFiscal: this.datoFiscalAgregar.regimenFiscal.c_regimen_fiscal,
        regimenFiscalDescripcion: this.datoFiscalAgregar.regimenFiscal.descripcion,
      };

      return facturaServices.editarDatoFiscal(params)
        .then(data => {
          this.alertShow("Se editó el dato fiscal correctamente.", "exito");
          this.$emit("get-dato-fiscal", data.data);
          this.onClose();
        }).catch(error => this.alertShow(error, "error"))
        .then(() => this.loader(false));
    },
    resetDatosFiscales(){
      this.datoFiscalAgregar.usoCfdi = "";
      this.datoFiscalAgregar.regimenFiscal = "";
    },
  }
}
